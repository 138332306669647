import React from 'react';
import { useState, useEffect, useRef } from 'react';

import StartIcon from '@mui/icons-material/Start';
import DiamondIcon from '@mui/icons-material/Diamond';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';
import FlakyIcon from '@mui/icons-material/Flaky';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import StatusIcon from '../Components/StatusIcon';

function PolicyTrackline(props){
    const progressBarStatus = props?.policyHandler?.groupStatus("Closed") === "active" ? "inactive" : '';
    const closedStatuses = ["Closed Won", "Client Not Interested", "Closed Lost", "Closed Lost no bid", "Closed Lost with Bid", "Unqualified Closed", "Sent to 3rd Party"];
    const policyHandler = props?.policyHandler;

    function printTrackedCategory(status, group, overideStatus){
        const isActive = props?.policyHandler?.policyPositioning?.[group]?.[status];
        const currentStatus = overideStatus ?? status;
        const isActiveClass = isActive === "failed" ? " failed" : isActive ? " active" : " inactive";
        // const isCurrentStatus = props?.currentPolicy?.policyStatus === status;
        const nextStatus = props?.session?.case?.functions?.nextStatus(status) ===  status;

        return (
            <div className={`categoryBlock g cC bR bold${nextStatus ? " next" : ''}${isActiveClass}`}>
                <div className="icon f cC">
                    <StatusIcon currentStatus={currentStatus} />
                </div>
                <div className="cL f oH">
                    <div className="f cL gCW">
                        {policyHandler?.insuredFriendlyStatus[currentStatus]}
                    </div>
                </div>
                <div className="f cC">
                    {props?.viewType !== "mobile" && props?.envFunctions?.reformatDate(props?.session?.case?.data?.tracklineTimestamp?.[props?.currentPolicy?.relatedPolicyID]?.[status], "friendly", props?.viewType)}
                </div>
                <div className="checkStatus cC">
                {nextStatus ? 
                    <>
                        <div className="f cC">
                            Preparing
                        </div>
                        <div className="f cC">
                            <PendingOutlinedIcon />
                        </div>
                    </>
                :
                    (isActive === "failed" ?
                        <HighlightOffRoundedIcon/>
                    :
                        isActive ?
                            <CheckCircleIcon />
                        :
                            <RadioButtonUncheckedIcon />
                    )
                }
                </div>
            </div>
        );
    }

    function printTrackHeader(position, group){
        let deathBenefit = 0;
        let count = 0;

        const positionData = {
            first: {
                category : "Preparation",
                icon : <StartIcon/>
            },
            second : {
                category : "Valuation",
                icon : <DiamondIcon/>
            },
            third : {
                category : "Contracts",
                icon : <GavelIcon/>
            },
            fourth : {
                category : "Closing",
                icon : <TaskIcon/>
            },
            fifth : {
                category : progressBarStatus === "inactive" ? "Closed" : props?.currentPolicy?.status === "Closed Won" ? "Complete" : "In Progress",
                icon :  progressBarStatus === "inactive" ? <DoDisturbOnIcon/> : props?.currentPolicy?.status === "Closed Won" ? <CelebrationIcon/> : <FlakyIcon/>
            }
        };

        const positionObj = {
            first: [
                "Lead",
                "Application Sent",
                "Meds/Illustration Ordered",
                "Underwriting (LE's)"
            ],
            second: [
                "Pre-Priced",
                "Pricing / Valuation",
                "Shipped",
                "Bids"
            ],
            third: [
                "Offer Letter Out / CRF",
                "Offer Letter In",
                "Contracts Out",
                "Contracts Back",
                "Contracts Back (Digital)"
            ],
            fourth: [
                "Escrow / At Carrier",
                "Rescission",
                // "Invoicing"
            ],
            fifth: closedStatuses
        };

        const arrayToIterate = positionObj[position];

        for (const item of arrayToIterate) {
            const deathBenefitValue = props?.chartData?.[item]?.deathBenefit;
            const countValue = props?.chartData?.[item]?.count;

            if (!isNaN(deathBenefitValue)) {
                deathBenefit += deathBenefitValue;
            }

            if (!isNaN(countValue)) {
                count += countValue;
            }
        }

        let headerClass = "header cC f " + position + " " + props?.policyHandler?.groupStatus(group);
        if(progressBarStatus === "inactive"){
            headerClass += " closed";
        }

        return (
            <div className={headerClass}>
                <div className="bullet f">
                </div>
                <div className="cC f g bold oH">
                    <div className="cC">
                        {positionData?.[position]?.icon}
                    </div>
                    <div className=" cC g f gCW">
                        {positionData?.[position]?.category}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="track fC g b">
            <div className={`progressTrack g f cC fC${progressBarStatus === "inactive" ? " inactive" : ''}`}>
                {printTrackHeader("first", "Preparation")}
                <div className={"category f first " + props?.policyHandler?.groupStatus("Preparation")}>
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Lead", "Preparation")}
                        {printTrackedCategory("Application Sent", "Preparation")}
                        {printTrackedCategory("Medical Records on Order", "Preparation")}
                        {printTrackedCategory("Illustration on order", "Preparation")}
                        {/* {printTrackedCategory("Meds/Illustration Ordered", "Preparation")} */}
                        {printTrackedCategory("Underwriting (LE's)", "Preparation")}
                    </div>
                </div>
                {printTrackHeader("second", "Valuation")}
                <div className={"category f second " + props?.policyHandler?.groupStatus("Valuation")}>
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Pre-Priced", "Valuation")}
                        {printTrackedCategory("Pricing / Valuation", "Valuation")}
                        {printTrackedCategory("Shipped", "Valuation")}
                        {printTrackedCategory("Bids", "Valuation")}
                    </div>
                </div>
                {printTrackHeader("third", "Contracts")}
                <div className={"category f third " + props?.policyHandler?.groupStatus("Contracts")}>
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Offer Letter Out / CRF", "Contracts")}
                        {printTrackedCategory("Offer Letter In", "Contracts")}
                        {printTrackedCategory("Contracts Out", "Contracts")}
                        {printTrackedCategory("Contracts Back", "Contracts")}
                    </div>
                </div>
                {printTrackHeader("fourth", "Closing")}
                <div className={"category f fourth " + props?.policyHandler?.groupStatus("Closing")}>
                    <div className="vDivider f">
                    </div>
                    <div className="catWrap cC bR dP fC">
                        {printTrackedCategory("Escrow / At Carrier", "Closing", "In Escrow")}
                        {printTrackedCategory("Escrow / At Carrier", "Closing", "At Carrier")}
                        {printTrackedCategory("Rescission", "Closing")}
                        {/* {printTrackedCategory("Invoicing", "Closing")} */}
                    </div>
                </div>
                <div className={`divider fifth${progressBarStatus === "inactive" ? " closed" : props?.currentPolicy?.policyStatus === "Closed Won" ? " completed" : ''}`}>
                </div>
                <div className={`header cC f fifth ${progressBarStatus === "inactive" ? " closed" : props?.currentPolicy?.policyStatus === "Closed Won" ? " completed" : ""}`}>
                    <div className="cC f g bold oH gC2">
                        <div className="cC">
                            {progressBarStatus === "inactive" ? <DoDisturbOnIcon/> : props?.currentPolicy?.policyStatus === "Closed Won" ? <CelebrationIcon/> : <FlakyIcon/>}
                        </div>
                        <div className="cC g f gCW">
                            {progressBarStatus === "inactive" ? "Closed" : props?.currentPolicy?.policyStatus === "Closed Won" ? "Complete" : "In Progress"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PolicyTrackline;
