import React from 'react';
import { useState, useEffect, useRef } from 'react';

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CircularProgress from '@mui/material/CircularProgress';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';

import MessageBubble from './MessageBubble';

function MessageBoard(props) {
  const session = props?.session;
  const sessionUser = session?.user?.data;

  const [rows, setRows] = useState(1);
  const [maxRow, setMaxRow] = useState(1);
  const [messageVal, setMessageVal] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const scrollRef = useRef(null);
  const bottomHandle = useRef(null);
  const textBox = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);
  const [adaptationTracker, setAdaptationTracker] = useState(0);

  const [loadingStatus, setLoadingStatus] = useState(true);
  const transcript = props?.transcript;
  const setTranscript = props?.setTranscript;

  function sendMessage(){
    if(checkMessageEligibility(textBox.current.value)){
      return;
    }
  
    textBox.current.value = '';
    let int = Object.keys(transcript).length;
    let message = {};
    let tempMessage = {
      content: messageVal,
      originator : "Customer",
      loading : true,
      nameOfSender : sessionUser?.firstName + " " + sessionUser?.lastName,
      x : int
    }

    pullTranscript(tempMessage);
    resetMessageBox();

    let paramVals = {
      "relatedPolicyID" : props.currentPolicy?.relatedPolicyID,
      "message" : messageVal,
      "nameOfSender" : sessionUser?.firstName + " " + sessionUser?.lastName,
      "emailOfSender" : props?.session?.data?.email,
    };

    session?.env?.functions?.buildFetchRequest("sendMessage", paramVals)
    .then(response => response.json())
    .then(resData => {

      if(resData.status === 200){
        for (var currentMessage in resData.messageData){
          message = {
              content : resData.messageData.content,
              timeStamp : resData.messageData.timeStamp,
              nameOfSender : resData.messageData.nameOfSender,
              type : resData.messageData.type,
              originator : resData.messageData.originator,
              loading: false,
              x : int
          };
        }

        pullTranscript(message);
      }else{
        tempMessage.loading = "failed";
        setTranscript(transcript => ({
          ...transcript,
          [int] : tempMessage
        }));
        handleMessageBoard('');
      }
    }, (error) => {
        if (error) {
         console.log(error);
        }
    });
  }

  function resetMessageBox(){
    setMessageVal('');
    setRows(1);
    setMaxRow(1);
    setActiveStatus("");
  }

  function pullTranscript(nativeMessage){
    var transcriptData = [];

    if(nativeMessage){
      let messageObj = {
        content : nativeMessage.content,
        originator : nativeMessage.originator,
        timeStamp : nativeMessage.timeStamp,
        nameOfSender : sessionUser?.firstName + " " + sessionUser?.lastName,
        loading : true,
        x : nativeMessage.x                         
      }
  
      transcriptData = {...transcript};

      if(nativeMessage.loading === false){
        messageObj.loading = false;
      }
  
      setTranscript(transcript => ({ ...transcript, [nativeMessage.x] : messageObj }));
      scrollToBottom();

    }else{
      var x = 0;

      session?.env?.functions?.buildFetchRequest("pullTranscript")
      .then(response => response.json())
      .then(resData => {

        setLoadingStatus(false);

        if(resData.status === 200){
          let returningFormat;
          returningFormat = "policyTranscriptData";

          for (var currentMessage in resData[returningFormat]){
            let formatedMessage = resData[returningFormat][currentMessage];
            let message = {
              content : formatedMessage.content,
              nameOfSender : formatedMessage.nameOfSender,
              type : formatedMessage.type,
              originator : formatedMessage.originator,
              timeStamp : formatedMessage.timeStamp,
              loading : false,
              x : currentMessage
            };

            transcriptData[currentMessage] = message;
          }

          setTranscript(transcriptData);
          updateMessageReadStatus();
        }
      });
    }
  }

  function printTranscript() {
    if (loadingStatus) {
      return (
        <div className="f cC g">
          <CircularProgress/>
          <div className="prompt bold cC">
            Loading Conversation
          </div>
        </div>
      );
    }
  
    if (!transcript) {
      return (
        <div className="preTranscriptContainer g f cC">
          <QuestionAnswerTwoToneIcon/>
          <div className="prompt bold">
            <div>
              Have any questions?
            </div>
            <div>
              Start a conversation
            </div>
          </div>
        </div>
      );
    }
  
    let transcriptMessages = [];
    let lastNameOfSender = null;
  
    for (const key of Object.keys(transcript)) {
      transcriptMessages.push(
        <MessageBubble
          key={key}
          messageKey={key}
          messageData={transcript[key]}
          lastDate={key > 0 ? transcript[key - 1].timeStamp : null}
          lastNameOfSender={lastNameOfSender}
        />
      );
      lastNameOfSender = transcript[key].nameOfSender;
    }
  
    return <>{transcriptMessages}</>;
  }

  function updateMessageReadStatus(){
    let paramVals = {
        "type" : "messages",
        "relatedID" : props.currentPolicy?.relatedPolicyID,
        "unread": false
    };

    session?.env?.functions?.buildFetchRequest("updateReadStatus", paramVals)
      .then(response => response.json())
      .then(resData => {
        if(resData.status === 200){
            //props.loadNotifications();
        }
    });
  }

  function handleScroll(scrollObj){
    const position = scrollObj.target.scrollTop;
    setScrollPosition(position);
    //setMaxScrollHeight(scrollObj.target.clientHeight - ((maxRow * 16) + 38));
  };

  function maintainScrollPosition(adaptiveScrollTop){
    if(scrollRef.current.scrollTop > (maxScrollHeight / 2)){
      scrollRef.current.scrollTop = scrollPosition + adaptiveScrollTop;
    }
  }

  function handleMessageBoard(currentMessageVal) {
    const value = currentMessageVal.target.value;
    const rowCount = value.split("\n").length;
    const adaptiveScroll = rowCount <= 4;
  
    setMessageVal(value);
  
    if (value.trim() && activeStatus === "" && !checkMessageEligibility(value)) {
      setActiveStatus(" active");
    } else if (!value.trim() && activeStatus !== "") {
      setActiveStatus("");
    }
  
    if (rowCount !== rows) {
      setRows(rowCount > 4 ? 4 : rowCount);
      setMaxRow(adaptiveScroll ? rowCount : 4);
      setAdaptationTracker((adaptiveScroll ? 1 : -1) * 12);
    }
  }

  function checkMessageEligibility(textVal) {
    return textVal === undefined || textVal.trim() === '';
  }

  const scrollToBottom = () => {
    if(props?.viewType === "full" && !props?.inputOnly){
      bottomHandle.current?.scrollIntoView({ behavior: "auto" });
    }else{
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

   useEffect(() => {
    if(!props?.inputOnly){
      pullTranscript();
    }
   }, []);

  useEffect(() => {
    if(!props?.inputOnly){
      printTranscript();
      scrollToBottom();
    }
  }, [transcript, props?.viewType]);


  useEffect(() => {
    if(!props?.inputOnly){
      maintainScrollPosition(adaptationTracker);
    }
  }, [maxRow, props?.viewType]);

  return (
    <>
      {!props?.inputOnly &&
        <div
          key="messageBoard"
          className={`policyDetailsMessageBoard dP pR${loadingStatus || !transcript ? " loading cC g f" : ''}`}
          style={{"bottom" : transcript && props?.viewType === "full" ? (maxRow === 1 ? 0 : maxRow * 12) + "px" : 0}}
          onScroll={handleScroll} ref={scrollRef}
        >
          { printTranscript() }
          {transcript &&
            <div
              ref={bottomHandle}
              className="messageBoardBottomHandle"
            >
            </div> 
          }
        </div>
      }
      {(props?.viewType === "full" || props?.inputOnly) &&
        <div
          key="messageBoardBottom"
          className={`messageBoardBottomBar bC g${!transcript ? " alt" : ''}`}
        >
          <div className="f">
              <div className="messageBoardBottomBarInnerWrap">
                <textarea
                  className="messageBox"
                  placeholder="Message"
                  rows={rows}
                  onChange={handleMessageBoard}
                  ref={textBox}
                  onKeyPress={(e) => {
                    if(e.key === 'Enter' && !e.shiftKey){sendMessage()}
                    if(e.key === 'Enter' && !e.shiftKey && checkMessageEligibility(e.value)){ e.preventDefault(); }
                  }}/>
              </div>
          </div>
            <div className={"messageBoxSendBtn f cC " + activeStatus} onClick={activeStatus !=="" ? () => {sendMessage()} : null}>
              <div className="messageBoxSendBtnInnerWrap">
                <SendRoundedIcon/>
              </div>
            </div>
        </div>
      }
    </>
   );
  }

  export default MessageBoard;