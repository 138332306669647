import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import TimelineIcon from '@mui/icons-material/Timeline';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import Tab from '../Components/Tab.js';
import Logo from '../Images/Logo.png';

function NavBar(props){
    const session = props?.session;
    const caseData = session?.case?.data;
    const userFunctions = session?.user?.functions;
    const currentPage = session?.user?.data?.currentActivity;
    const viewType = session?.env?.viewport?.viewType;
    const hasNotifications = !!Object.keys(session?.notifications?.data || {}).length;

    const navigate = useNavigate();
    const currentPath = window.location.pathname.replace(/\//g, '');

    function scrollPolicy(direction) {
        const policyKeys = Object.keys(caseData?.policy);
        const currentIndex = policyKeys.indexOf(caseData?.currentPolicyKey);
        const nextIndex = (currentIndex + 1) % policyKeys.length;
        const prevIndex = (currentIndex - 1 + policyKeys.length) % policyKeys.length;
        
        if (direction === 'next'){
            session?.case?.functions?.updateCurrentPolicyKey(policyKeys[nextIndex]);
        } else if (direction === 'prev'){
            session?.case?.functions?.updateCurrentPolicyKey(policyKeys[prevIndex]);
        }
    }

    function toggleOverlay(overlay){
        session?.env?.setOverlay(session?.env?.overlay === overlay ? undefined : overlay);
    }

    function printPolicyScroller(){
        return (
            <div
                className={`${Object.keys(caseData?.policy).length >= 3 || viewType === "mobile" ? "btnWIcon g p" : "btn"} bR mA fMP nS bold dP`}
                onClick={() => Object.keys(caseData?.policy).length >= 3 || viewType === "mobile" ? toggleOverlay("policyScroller") : null}
            >
                <div className="f cC oH">
                    <div className="gCW">
                        {props?.currentPolicy?.policyNumber}
                    </div>
                </div>
                <div className="f cC">
                    {(Object.keys(caseData?.policy).length >= 3 || viewType === "mobile") && (
                        session?.env?.overlay === "policyScroller" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    )}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (viewType === "full" && currentPage === "details") {
            navigate("/timeline");
            userFunctions?.updateUserData(["currentActivity"], ["timeline"]);
        }
    }, [viewType, currentPage]);

    useEffect(() => {
        if (!session?.user?.data?.currentActivity) {
            userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["timeline"]);
        }
    }, [session?.user?.data, currentPath]);
    
    useEffect(() => {
        const validPaths = ["timeline", "documents", "messages", "details"];

        if(currentPath && validPaths.includes(currentPath) && session?.user?.data?.currentActivity !== currentPath){
           userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["timeline"]);
        }else if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
        }
    }, [currentPath]);

    useEffect(()=>{
        const validPaths = ["timeline", "documents", "messages", "details", "unsubscribe"];

        if(!currentPath || !validPaths.includes(currentPath)){
            navigate("/timeline");
            userFunctions?.updateUserData(["currentActivity"], ["timeline"]);
        }

        if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
            if(viewType === "mobile"){
                navigate("/details");
            }else{
                navigate("/timeline");
            }
            window.history.replaceState(null, '', '/timeline');
        }
    }, []);

    return (
        <div className={`appHeader g f fR cC${viewType === "mobile" ? '' : " moduleBlock"}`}>
            <div className="logo f cC">
                <img className="f cC" src={Logo} alt="Abacus Life Logo"/>
            </div>
            <div className="f cC">
                {caseData?.policy && Object.keys(caseData?.policy).length > 1 ?
                    <div className="policyNav g cC f fR">
                        {viewType !== "mobile" &&
                            <div className="scroll cC p" onClick={()=>{scrollPolicy("prev")}}>
                                <ArrowLeftIcon/>
                            </div>
                        }
                        {printPolicyScroller()}
                        {viewType !== "mobile" &&
                            <div className="scroll cC p" onClick={()=>{scrollPolicy("next")}}>
                                <ArrowRightIcon/>
                            </div>
                        }
                    </div>
                :
                    <div className="f cC bold">
                        {props?.currentPolicy ? props?.currentPolicy?.policyNumber : "Loading..."} 
                    </div>
                }
            </div>
                <div className={`tabs g cC fR f nS fC${viewType !== "full" ? " alt" : ''}`}>
                    <div className="f cC fR g">
                            {viewType === "medium" &&
                                <Tab
                                    isActive={currentPage === "details"}
                                    onClick={() => userFunctions?.updateUserData(["currentActivity"], ["details"])}
                                    label="Details"
                                    icon={<FolderSharedTwoToneIcon />}
                                    viewType={viewType}
                                    session={session}
                                />
                            }
                            {viewType !== "mobile" &&
                                <>
                                    <Tab
                                        isActive={currentPage === "timeline"}
                                        onClick={() => userFunctions?.updateUserData(["currentActivity"], ["timeline"])}
                                        label="Timeline"
                                        icon={<TimelineIcon />}
                                        alertType={"Status"}
                                        viewType={viewType}
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                    />
                                    <Tab
                                        isActive={currentPage === "documents"}
                                        onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["documents"])}
                                        label="Documents"
                                        icon={<HistoryEduTwoToneIcon />}
                                        viewType={viewType}
                                        session={session}
                                    />
                                    <Tab
                                        isActive={currentPage === "messages"}
                                        onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["messages"])}
                                        label="Messages"
                                        icon={<ForumTwoToneIcon />}
                                        viewType={viewType}
                                        alertType={"Message"}
                                        session={session}
                                    />
                                </>
                            }
                            <div className="tab alt g f cC p fC" onClick={() => toggleOverlay("notifications")}>
                            {
                                hasNotifications ?
                                    <div className="notificationsBtn f cC">
                                        <NotificationsActiveRoundedIcon/>
                                        <div className="count cC">
                                            {Object?.keys(session?.notifications?.data).length}
                                        </div>
                                    </div>
                                :
                                    <NotificationsNoneRoundedIcon/>
                            }
                        </div>
                        <div className="tab alt g f cC p fC" onClick={() => toggleOverlay("settings")}>
                            <SettingsIcon/>
                        </div>
                    </div>
                </div>            

        </div>
    )
};

export default NavBar;