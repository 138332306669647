import React, { useEffect, useState } from 'react';

function Initialization(props){
    const [loadHandler, setLoadHandler] = useState({
        policyTrackline : false,
        policyData : false,
        shareLinkData : false,
        notificationData : false
    });

    function loadlPolicyTracklineData(session){
        const paramVals = {
            accountID : session?.user?.data?.accountID
        }
        setLoadHandler(prevState => ({
            ...prevState,
            policyTrackline: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("pullHomePageTimeline", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                buildPolicyTrackline(session, resData.activityList);
                setLoadHandler(prevState => ({
                    ...prevState,
                    policyTrackline: true
                }));
            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    policyTrackline: false
                }));
            }
        });
    }

    function buildPolicyTrackline(session, activityList) {
        let tracklineData = {};
        let tracklineTimestamp = {};

        const buildTrackline = (obj) => {
            for (const key in obj) {
                if (typeof obj[key] === "object" && obj[key] !== null) {
                    if (tracklineData[key] === undefined) {
                        tracklineData[key] = new Set();
                    }

                    for (const nestedKey in obj[key]) {
                        const status = obj[key][nestedKey].status;
                        const timestamp = obj[key][nestedKey].rawTimeStamp;
                        if (status) {
                            tracklineData[key].add(status);
                            if (!tracklineTimestamp[key]) {
                                tracklineTimestamp[key] = {};
                            }
                            if (tracklineTimestamp[key][status]) {
                                tracklineTimestamp[key][status].push(timestamp);
                            } else {
                                tracklineTimestamp[key][status] = [timestamp];
                            }
                        }
                    }
                }
            }
        };

        buildTrackline(activityList);

        for (const key in tracklineData) {
            if (tracklineData[key].has("Meds/Illustration Ordered") || tracklineData[key].has("Underwriting (LE's)")) {
                if(!tracklineData[key].has("Medical Records on Order")){
                    tracklineData[key].add("Medical Records on Order");
                }

                if(!tracklineData[key].has("Illustration on order")){
                    tracklineData[key].add("Illustration on order");
                }
            }

            if (tracklineData[key].has("Escrow / At Carrier")) {
                if(!tracklineData[key].has("Contracts Back")){
                    tracklineData[key].add("Contracts Back");
                }

                if(!tracklineData[key].has("Contracts Back (Digital)")){
                    tracklineData[key].add("Contracts Back (Digital)");
                }
            }

            tracklineData[key] = Array.from(tracklineData[key]);
        }

        session?.case?.functions?.loadCase(tracklineData, "trackline");
        session?.case?.functions?.loadCase(tracklineTimestamp, "tracklineTimestamp");
    }

    function loadPolicyData(session){
        const paramVals = {
            accountID : session?.user?.data?.accountID
        }

        setLoadHandler(prevState => ({
            ...prevState,
            policyData: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("pullAccountPolicies", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                const caseData = resData.policyRecords;

                session?.case?.functions?.loadCase(caseData, "policy");
                setLoadHandler(prevState => ({
                    ...prevState,
                    policyData: true
                }));
            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    policyData: false
                }));
            }
        });
    }

    function loadShareLinkData(session){
        const paramVals = {
            pullType : "insured"
        }

        setLoadHandler(prevState => ({
            ...prevState,
            shareLinkData: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("getShareLinks", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                session?.shareLink?.functions?.loadShareLink(resData.policyData);
                setLoadHandler(prevState => ({
                    ...prevState,
                    shareLinkData: true
                }));
            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    shareLinkData: false
                }));
            }
        });
    }

    function formatNotifications(data) {
        if (!data) {
            return false;
        }

        const sortByTimestamp = (a, b) => new Date(b[1].timeStamp) - new Date(a[1].timeStamp);
        const isAllowedActivityType = (activityType) => ["Message", "Status", "Bid"].includes(activityType);

        const sortedNotifications = Object.entries(data).sort(sortByTimestamp);
        const processedRelatedIDs = { Message: {}, Status: {}, Bid: {} };

        const processNotification = (result, [key, notification]) => {
            const { relatedID, activityType } = notification;
            const processedNotification = processedRelatedIDs[activityType]?.[relatedID];
    
            if (isAllowedActivityType(activityType) && (!processedNotification || (processedNotification.counter++ >= 0))) {
                processedNotification || (processedRelatedIDs[activityType][relatedID] = { counter: 0 });
                result.push([key, notification]);
            }

            notification.skippedSteps = processedNotification?.counter || 0;
            return result;
        };

        const filteredNotifications = sortedNotifications.reduce(processNotification, []);

        return filteredNotifications;
    }

    function loadNotificationData(session){
        const paramVals = {
            accountID : session?.user?.data?.accountID
        }

        setLoadHandler(prevState => ({
            ...prevState,
            notificationData: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("pullNotifications", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                setLoadHandler(prevState => ({
                    ...prevState,
                    notificationData: true
                }));
                
                const notifications = formatNotifications(resData?.notificationData);
                if(notifications){
                    session?.notifications?.functions?.loadNotifications(notifications);
                }

            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    notificationData: false
                }));
            }
        });
    }

    function resetLoaders(session){
        setLoadHandler({
            policyTrackline : false,
            policyData : false,
            shareLinkData : false,
            notificationData : false
        });

        session?.case?.functions?.loadCase("reset");
        session?.shareLink?.functions?.loadShareLink(undefined);
        session?.notifications?.functions?.loadNotifications(undefined);
    }

    const initialize = {
        loadHandler,
        setLoadHandler,
        loadlPolicyTracklineData,
        loadPolicyData,
        loadShareLinkData,
        loadNotificationData,
        resetLoaders
    }

  return initialize;
};

export default Initialization;