import EnvVars from './EnvVars.js';
import SessionUser from './SessionUser.js';
import SessionCase from './SessionCase.js';
import ShareLink from './ShareLink.js';
import Notifications from './Notifications.js';

function SessionVars(props){
    const sessionUser = SessionUser();
    const sessionCase = SessionCase();
    const shareLink = ShareLink();
    const notifications = Notifications();
    const envVars = EnvVars(sessionUser);

    const sessionVars = {
        env : envVars,
        user : sessionUser,
        case : sessionCase,
        shareLink,
        notifications
    }

    return sessionVars;
};

export default SessionVars;