import React, { useEffect, useState } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ShieldIcon from '@mui/icons-material/Shield';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GroupIcon from '@mui/icons-material/Group';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import PanToolIcon from '@mui/icons-material/PanTool';

import InputField from "../Components/InputField.js";

function PolicyForm(props){
    const session = props?.session;
    const caseFunctions = session?.case?.functions;
    const policyHandler = session?.case?.policyHandler;
    const envFunctions = props?.envFunctions;

    const onHold = props?.currentPolicy?.policyStatus === "On Hold";

    const renderInputField = (label, value ) => {
        return (
          <InputField value={value ? value : "N/A"} placeholder={label} name={label.toLowerCase()} readOnly={true}/>
        );
    };

    const ContactBlock = ({ viewType, currentPolicy }) => {
        const isMobile = viewType === "mobile";

        return (
          <div className={`contactBlock g f fC cC${isMobile ? " fR" : ""}`}>
            <div className="header g f fR">
              <div className={isMobile ? "cC" : "tC"}>
                <SupportAgentIcon />
              </div>
              <div className={isMobile ? "cL" : "tL"}>Assistance</div>
              {!isMobile && (
                <>
                  <div className="f tR e">
                    {currentPolicy?.currentClientContactName}
                  </div>
                  <div className="profilePic f cC">
                    {currentPolicy?.currentClientContactImg ?
                      <img src={currentPolicy?.currentClientContactImg}/>
                    :
                      <AccountCircleIcon />
                    }
                  </div>
                </>
              )}
            </div>
            <div className="contactBar g f cC fR bold">
              {isMobile && (
                <div className="f cC g fR">
                  <div className="profilePic f cC">
                    {currentPolicy?.currentClientContactImg ?
                      <img src={currentPolicy?.currentClientContactImg}/>
                    :
                      <AccountCircleIcon />
                    }
                  </div>
                  <div className="f cC">
                    {currentPolicy?.currentClientContactName}
                  </div>
                </div>
              )}
              <a href={"mailto:" + currentPolicy?.currentClientContactEmail}>
                <div className={`option contactInfo g fR p bR ${isMobile ? "f cC" : "cL dP s e"}`}>
                  <div className={isMobile ? "bR cC" : "f cC"}>
                    <EmailIcon />
                  </div>
                  {!isMobile && (
                    <div className="f cL oH">
                      <div className="gCW">
                        {currentPolicy?.currentClientContactEmail}
                      </div>
                    </div>
                  )}
                </div>
              </a>
              <a href={"tel:" + currentPolicy?.currentClientContactPhone}>
                <div className={`option contactInfo g fR p bR ${isMobile ? "f cC" : "cL dP s e"}`}>
                  <div className={isMobile ? "bR cC" : "f cC"}>
                    <CallIcon />
                  </div>
                  {!isMobile && (
                    <div className="f cL oH">
                      <div className="gCW">
                        {currentPolicy?.currentClientContactPhone}
                      </div>
                    </div>
                  )}
                </div>
              </a>
            </div>
          </div>
        );
    };

    function bidInfoBlock() {
      const isProgressToBidsLessThan100 = caseFunctions?.progressToBids(props?.currentPolicy?.policyStatus) < 100;
      const isStatusValueBidsInactive = !caseFunctions?.getStatusValue("Bids");
      const isPolicyHandlerClosedActive = policyHandler.groupStatus("Closed") === "active";
      const currentGroup = policyHandler.currentGroup(props?.currentPolicy?.policyStatus);

      let classNames = "bidInfo g f fR cC";

      if ((isProgressToBidsLessThan100 || isStatusValueBidsInactive) && !onHold) {
        classNames += " inactive";
      }

      if (isPolicyHandlerClosedActive) {
        classNames += " closed";
      } else if (onHold){
        classNames += " onHold";
      } else {
        classNames += ` ${currentGroup}`;
      }

      return classNames;
    }

    function bidPrompt() {
        const currentGroup = policyHandler?.currentGroup(props?.currentPolicy?.policyStatus);
        const failedStatus = caseFunctions?.getFailedStatus();
        const failedGroup = caseFunctions?.getFailedGroup();

        const getPromptMessage = () => {
          if (failedStatus) {
            return `${policyHandler?.insuredFriendlyStatus[failedStatus]} closed during ${failedGroup} process.`;
          } else if (currentGroup === "Preparation") {
            return "We're preparing your case for valuation.";
          } else if (onHold){
            return "This case has been placed on hold."
          } else {
            return "Your case is being valued for offers.";
          }
        };

        const progressWidth = failedStatus
          ? caseFunctions?.progressToBids(failedStatus) + "%"
          : caseFunctions?.progressToBids(props?.currentPolicy?.policyStatus) + "%";

        return (
          <>
            {!failedStatus && !onHold &&
                <div className="f cC">
                    <ContentPasteSearchRoundedIcon />
                </div>
            }
            <div className="prompt g f cC fC">
              <div className="f cC">
                {
                  getPromptMessage()
                }
              </div>
              {!failedStatus && !onHold &&
                <div className="f cC">
                      <div className="progressBar">
                          <div className="progress" style={{width: progressWidth}}>
                          </div>
                      </div>   
                </div>
              }
            </div>
            <div className="icon2 f cC">
                {failedStatus || onHold ?
                    <>
                        <div className="f cC e">
                            {failedStatus &&
                              <DoNotDisturbOnRoundedIcon/>
                            }
                            {onHold &&
                              <PanToolIcon/>
                            }
                        </div>
                        <div className="f cC e">
                            {failedStatus &&
                              "Closed"
                            }
                            {onHold &&
                              "On Hold"
                            }
                        </div>
                    </>

                :
                    <PriceChangeRoundedIcon/>
                }
            </div>
          </>
        );
    }

    if(props?.viewType === "full"){
        return null;
    }

    return (
        <div className="policyForm g f oH fC">
            <ContactBlock
                viewType={props?.viewType}
                currentPolicy={props?.currentPolicy}
            />
            <div className="bidBlock f g fR fC cC">
                <div className="header g f cC fR">
                    <div className="f cC">
                        <ThumbsUpDownRoundedIcon/>
                    </div>
                    <div className="f cL">
                        Bid Information
                    </div>
                </div>
                <div className={bidInfoBlock()}>
                    {caseFunctions?.getStatusValue("Bids") ?
                        <>
                          {props?.viewType !== "mobile" ?
                            <>
                              <div className={`section one g fC cC dP alt f`}>
                                  <div className="icon g cC f fR">
                                      <div className="f cC oH">
                                          <div className="gCW">
                                              {envFunctions?.convertIntToCurrency(props?.currentPolicy?.marketValue)}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="divider">
                                  </div>
                                  <div className="data f cC">
                                      Highest Bid
                                  </div>
                              </div>
                              <div className={`section two g fC cC dP f`}>
                                  <div className="icon g cC f fR">
                                      <VolunteerActivismRoundedIcon/>
                                      <div className="f cC">
                                          {props?.currentPolicy?.marketBidCount}
                                      </div>
                                  </div>
                                  <div className="divider">
                                  </div>
                                  <div className="data f cC">
                                      Bid Count
                                  </div>
                              </div>
                            </>
                          :
                            <div div className="condense g f">
                              <div className={`section one g fC cC dP alt`}>
                                <div className="icon g cC f fR">
                                    <div className="f cC oH">
                                        <div className="gCW">
                                            {envFunctions?.convertIntToCurrency(props?.currentPolicy?.marketValue)}
                                        </div>
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data f cC">
                                    Highest Bid
                                </div>
                              </div>
                              <div className={`section two g fC cC dP`}>
                                  <div className="icon g cC f fR">
                                      <VolunteerActivismRoundedIcon/>
                                      <div className="f cC">
                                          {props?.currentPolicy?.marketBidCount}
                                      </div>
                                  </div>
                                  <div className="divider">
                                  </div>
                                  <div className="data f cC">
                                      Bid Count
                                  </div>
                              </div>
                            </div>
                          }
                            <div className={`section three split g f fC cC dP`}>
                                <div className="icon g cC f">
                                  <div>
                                    <div className="f cC">
                                      Qualified
                                    </div>
                                    <div className="f cC">
                                      {props?.currentPolicy?.numQualifiedFunds ?? 0}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="f cC">
                                      Unqualified
                                    </div>
                                    <div className="f cC">
                                      {props?.currentPolicy?.numUnqualifiedFunds ?? 0}
                                    </div>
                                  </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data f cC">
                                    Funds Shown
                                </div>
                            </div>
                        </>
                        :
                        bidPrompt()
                    }
                </div>
            </div>
            <div className="formBlock g f">
                <div className="header g f cC fR">
                    <div className="f cC">
                        {props?.currentPolicy?.secondaryFirstName ? <GroupIcon/> : <PersonIcon/>}
                    </div>
                    <div className="f cL">
                        {props?.currentPolicy?.secondaryFirstName ? "Insureds" : "Insured"}
                    </div>
                    <div className="f cC">
                        <LockPersonIcon/>
                    </div>
                </div>
                <div className="form g">
                    {props?.currentPolicy?.secondaryFirstName &&
                        <div className="header cC">
                            Primary
                        </div>
                    }
                    <div className={`double g`}>
                        {renderInputField('Name', props?.currentPolicy?.primaryFirstName + " " + props?.currentPolicy?.primaryLastName)}
                        {renderInputField('Gender', props?.currentPolicy?.primaryGender)}
                        {renderInputField('Age', envFunctions?.calcAge(props?.currentPolicy?.primaryBirthDate))}
                        {renderInputField('Birth Date', envFunctions?.reformatDate(props?.currentPolicy?.primaryBirthDate))}
                        {renderInputField('Email', props?.currentPolicy?.primaryEmail)}
                        {renderInputField('Phone Number', props?.currentPolicy?.primaryPhoneNumber)}
                    </div>
                    {props?.currentPolicy?.secondaryFirstName &&
                        <>
                            <div className="header cC">
                                Secondary
                            </div>
                            <div className={`double g`}>
                                {renderInputField('Name', props?.currentPolicy?.secondaryFirstName + " " + props?.currentPolicy?.secondaryLastName)}
                                {renderInputField('Gender', props?.currentPolicy?.secondaryGender)}
                                {renderInputField('Age', envFunctions?.calcAge(props?.currentPolicy?.secondaryBirthDate))}
                                {renderInputField('Birth Date', envFunctions?.reformatDate(props?.currentPolicy?.secondaryBirthDate))}
                                {renderInputField('Email', props?.currentPolicy?.secondaryEmail)}
                                {renderInputField('Phone Number', props?.currentPolicy?.secondaryPhoneNumber)}
                            </div>      
                        </>

                    }
                </div>
                <div className="header g f cC fR">
                    <div className="f cC">
                        <ShieldIcon/>
                    </div>
                    <div className="f cL">
                        {"Policy " + props?.currentPolicy?.policyNumber}
                    </div>
                </div>
                <div className="form">
                    <div className="double g">
                        <InputField value={props?.currentPolicy?.insuranceCompany} placeholder={"Insurance Company"} name={"insuranceCompany"} readOnly={ true }/>
                        <InputField value={envFunctions?.reformatDate(props?.currentPolicy?.issueDate)} placeholder={"Issue Date"} name={"issueDate"} readOnly={ true }/>
                        <InputField value={props?.currentPolicy?.policyStatus} placeholder={"Abacus Life Status"} name={ "policyStatus"} readOnly={ true }/>
                        <InputField value={envFunctions?.convertIntToCurrency(props?.currentPolicy?.deathBenefit)} placeholder={"Death Benefit"} name={"deathBenefit"} readOnly={ true }/>
                        <InputField value={props?.currentPolicy?.ownerSitusState} placeholder={"Situs State"} name={"ownerSitusState"} readOnly={ true }/>
                        <InputField value={props?.currentPolicy?.ownerType} placeholder={"Owner Type"} name={"ownerType"} readOnly={ true }/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PolicyForm;