import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import SessionVars from './SessionVars/SessionVars.js';
import Initialization from './SessionVars/Initialization.js';
import NavBar from './NavBar/NavBar.js';
import InsuredPortal from './InsuredPortal/InsuredPortal.js';
import Login from './Public/Login.js';
import Rerouter from './Public/Rerouter.js';
import Unsubscribe from './Public/Unsubscribe.js';
import OverlayHandler from "./Components/Overlays/OverlayHandler.js";

function App() {
  const session = SessionVars();
  const initialize = Initialization();

  const currentPolicy = session?.case?.data?.policy?.[session?.case?.data?.currentPolicyKey];
  const policyTrackline = session?.case?.data?.trackline;
  const userFunctions = session?.user?.functions;
  const viewType = session?.env?.viewport?.viewType;

  useEffect(()=>{
    if(session?.case?.data?.policy && !session?.case?.data?.currentPolicyKey){
      session?.case?.functions?.updateCurrentPolicyKey(Object.keys(session?.case?.data?.policy)[0]);
    }
  }, [session?.case?.data]);

  useEffect(() => {
    if(!session?.user?.data?.sessionToken && !localStorage.getItem("sessionUser")){
      session?.env?.setOverlay();
    }

    if(session?.user?.data?.sessionToken && session?.user?.data){
      if(!initialize?.loadHandler?.notificationData){
        initialize?.loadNotificationData(session);
      }

      if(!initialize?.loadHandler?.policyData){
        initialize?.loadPolicyData(session);
      }

      if(!initialize?.loadHandler?.policyTrackline){
        initialize?.loadlPolicyTracklineData(session);
      }

      if(!initialize?.loadHandler?.shareLinkData){
        initialize?.loadShareLinkData(session);
      }
    }
  }, [session?.user?.data]);

  useEffect(() => {
    if(currentPolicy && policyTrackline){
      session?.case?.policyHandler?.updateStepStatus(policyTrackline?.[currentPolicy?.relatedPolicyID]);
    }
  }, [currentPolicy, policyTrackline]);

  useEffect(() => {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    if (!(storedSessionUser && storedSessionUser.application === "iP" && storedSessionUser.sessionToken)) {
      userFunctions?.logout();
    }
  }, []);

  useEffect(() => {
    const type = session?.user?.data?.currentActivity;
    const isTimeline = type === "timeline";
    const isMessages = type === "messages";
    const isDetails = type === "details" && viewType !== "full";

    if(!isMessages){
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
    let updatedNotifications = session?.notifications?.data?.filter(
      (notificationArray) =>
        !(
          (isTimeline && notificationArray[1]?.activityType === "Status" &&
            notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID) ||
          (isMessages && notificationArray[1]?.activityType === "Message" &&
            notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID) ||
          (isDetails && notificationArray[1]?.activityType === "Bid" &&
            notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID)
        )
    );
  
    session?.notifications?.functions?.loadNotifications(updatedNotifications);
    //Need to add conditions to update just timeline if need be, and or bids
    //Dependent on viewType and current activity
    //updating notifications for bids is dynamic
    if (session.notifications?.data?.length !== updatedNotifications?.length) {
      const paramVals = {
        type: "policy",
        relatedID: currentPolicy.relatedPolicyID,
        unread: false,
      };

      session?.env?.functions?.buildFetchRequest("updateReadStatus", paramVals)
        .then(response => response.json())
        .then(resData => {});
    }
  }, [currentPolicy, session?.user?.data]);

  return (
      <Router>
        {
          session?.user?.data?.sessionToken || JSON.parse(localStorage.getItem("sessionUser") || "{}").sessionToken !== undefined ?
            <>
              <OverlayHandler
                session={session}
                overlay={session?.env?.overlay}
                setter={session?.env?.setOverlay}
                handleClose={session?.env?.functions?.handleClose}
                currentPolicy={currentPolicy}
              />
              <div className={`App f g oH fC${viewType === "full" ? " cC" : ''}`}>
                <NavBar
                  session={session}
                  currentPolicy={currentPolicy}
                />
                <InsuredPortal
                  session={session}
                  currentPolicy={currentPolicy}
                  policyTrackline={policyTrackline}
                  initialize={initialize}
                />
              </div>
            </>
          :
            <Routes>
              <Route
                  path="/"
                  element={
                  <Login
                    session={session}
                    initialize={initialize}
                  />
                }
              />
              <Route
                  path="/unsubscribe"
                  element={
                  <Unsubscribe
                    session={session}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <Rerouter/>
                }
              />
            </Routes>
        }
      </Router>
  );
}

export default App;
