import React, { useState } from 'react';

function MessageBubble(props) {
  const [showStatus, setShowStatus] = useState('');

  const { messageData, lastNameOfSender, lastDate, messageKey } = props;

  let bubbleType = "sent";
  let messageTimeStamp = "messageTimeStamp";

  if (messageData.originator === "Internal" || messageData.originator === "Abacus") {
    bubbleType = "received";
  } else if (messageData.loading === true) {
    bubbleType = "loading";
  } else if (messageData.loading === "failed") {
    bubbleType = "failed";
  }

  if (messageData.nameOfSender !== lastNameOfSender) {
    messageTimeStamp = "messageTimeStamp full";
    bubbleType += " full";
  }

  function showTimeStamp(show) {
    setShowStatus(show ? " show" : '');
  }

  function convertTimeStamp(timeStamp, separator) {
    if (!timeStamp) {
      return '';
    }
    timeStamp = Date.parse(timeStamp);
    return separator
      ? new Date(timeStamp).toDateString("en-US")
      : new Date(timeStamp).toLocaleTimeString("en-US");
  }

  const shouldShowTimeStampSeparator = convertTimeStamp(lastDate, true) !== convertTimeStamp(messageData.timeStamp, true) || messageKey === 0;

  return (
    <>
      {shouldShowTimeStampSeparator && (
        <div key={messageData.timeStamp + "StampSeparator"} className="timeStampSeparator cC">
          {convertTimeStamp(messageData.timeStamp, true)}
        </div>
      )}

      <div
        key={messageTimeStamp + "Message"}
        className={`cC bR messageBubble ${bubbleType}${showStatus}`}
        onMouseEnter={() => showTimeStamp(true)}
        onMouseLeave={() => showTimeStamp(false)}
      >
        {messageData.content}

        <div>
          {messageTimeStamp === "messageTimeStamp full" && (
            <div className={`messageNameOfSender${showStatus}`}>
              {messageData.nameOfSender !== "undefined undefined" ? messageData.nameOfSender : "N/A"}
            </div>
          )}

          <div className={`${messageTimeStamp} ${showStatus}`}>
            <div className="messageTimeStampInnerWrap">
              <div>{convertTimeStamp(messageData.timeStamp)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageBubble;