import React, { useEffect, useState } from 'react';

import StartIcon from '@mui/icons-material/Start';
import DiamondIcon from '@mui/icons-material/Diamond';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';

function ProgressionBar(props){
    const session = props?.session;
    const policyHandler = props?.policyHandler;

    function groupCompletionPercentage(group) {
        const steps = Object.keys(policyHandler?.policyPositioning[group]);
        const completedSteps = steps.filter((step) => policyHandler?.policyPositioning[group][step]);

        return (completedSteps.length / steps.length) * 100 + "%";
    }

    const progressBarStatus = props?.policyHandler?.groupStatus("Closed") === "active" ? "inactive" : '';

    return (
        <div className={`policyProgressionBlock g f cC fC ${progressBarStatus}${props?.viewType === "mobile" ? " gR2" : " gR1"}${props?.viewWidth <= 539 ? " alt" : ''}`}>
            <div className="progressBar g f fR cC">
                <div className={"step first cC f gC2 " + policyHandler?.groupStatus("Preparation")}>
                    {policyHandler?.groupStatus("Preparation") === "completed" &&
                        <div className="completionMark">
                        </div>
                    }
                    <div className="icon cC">
                        <StartIcon/>
                    </div>
                </div>
                <div className={"first data cC " + policyHandler?.groupStatus("Preparation")}>
                    <div className="progress" style={{width: groupCompletionPercentage("Preparation")}}>
                    </div>
                </div>
                <div className={"step second cC f " + policyHandler?.groupStatus("Valuation")}>
                    {policyHandler?.groupStatus("Valuation") === "completed" &&
                        <div className="completionMark">
                        </div>
                    }
                    <div className="icon cC">
                        <DiamondIcon/>
                    </div>
                </div>
                <div className={"second data cC " + policyHandler?.groupStatus("Valuation")}>
                    <div className="progress" style={{width: groupCompletionPercentage("Valuation")}}>
                    </div>
                </div>
                <div className={"step third cC f " + policyHandler?.groupStatus("Contracts")}>
                    {policyHandler?.groupStatus("Contracts") === "completed" &&
                        <div className="completionMark">
                        </div>
                    }
                    <div className="icon cC">
                        <GavelIcon/>
                    </div>
                </div>
                <div className={"third data cC " + policyHandler?.groupStatus("Contracts")}>
                    <div className="progress" style={{width: groupCompletionPercentage("Contracts")}}>
                    </div>
                </div>
                <div className={"step fourth cC f " + policyHandler?.groupStatus("Closing")}>
                    {policyHandler?.groupStatus("Closing") === "completed" &&
                        <div className="completionMark">
                        </div>
                    }
                    <div className="icon cC">
                        <TaskIcon/>
                    </div>
                </div>
                <div className={"fourth data cC " + policyHandler?.groupStatus("Closing")}>
                    <div className="progress" style={{width: groupCompletionPercentage("Closing")}}>
                    </div>
                </div>
                <div className={"step fifth cC f " + policyHandler?.groupStatus("Completed")}>
                    {policyHandler?.groupStatus("Completed") === "completed" &&
                        <div className="completionMark">
                        </div>
                    }
                    <div className="icon cC">
                        {progressBarStatus === "inactive" ?
                            <DoNotDisturbOnRoundedIcon/>
                        :
                            <CelebrationIcon/>
                        }
                    </div>
                </div>
            </div>
            <div className="stages f g cC">
                <div className={`f cC gC2 ${policyHandler?.groupStatus("Preparation") !== "inactive" ? 'bold' : ''}`}>
                    {props.viewWidth <= 539 ? "Step 1" : "Preparation"}
                </div>
                <div className={`f cC gC4 ${policyHandler?.groupStatus("Valuation") !== "inactive" ? 'bold' : ''}`}>
                {props.viewWidth <= 539 ? "Step 2" : "Valuation"}
                </div>
                <div className={`f cC gC6 ${policyHandler?.groupStatus("Contracts") !== "inactive" ? 'bold' : ''}`}>
                {props.viewWidth <= 539 ? "Step 3" : "Contracts"}
                </div>
                <div className={`f cC gC8 ${policyHandler?.groupStatus("Closing") !== "inactive" ? 'bold' : ''}`}>
                {props.viewWidth <= 539 ? "Step 4" : "Closing"}
                </div>
                <div className={`f cC gC10 ${policyHandler?.groupStatus("Completed") !== "inactive" || policyHandler?.groupStatus("Closed") !== "inactive" ? 'bold' : ''}`}>
                    <div>
                        {props.viewWidth <= 539 ?
                            progressBarStatus === "inactive" ?
                                "Closed"
                            :
                            policyHandler?.groupStatus("Completed") !== "inactive" ?
                                "Finished"
                            :
                                "Finish"
                        :
                            progressBarStatus === "inactive" ?
                                "Closed"
                            :
                                "Complete"
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProgressionBar