import React, { useEffect, useState } from 'react';

function SessionCase(props){
  const [policyPositioning, setPolicyPositioning] = useState({
    "Preparation" : {
      "Lead": false,
      "Application Sent": false,
      "Medical Records on Order" : false,
      "Illustration on order" : false,
      "Meds/Illustration Ordered": false,
      "Underwriting (LE's)": false,
  },
    "Valuation" : {
      "Pre-Priced": false,
      "Pricing / Valuation": false,
      "Shipped": false,
      "Bids": false,
    },
    "Contracts" : {
      "Offer Letter Out / CRF": false,
      "Offer Letter In": false,
      "Contracts Out": false,
      "Contracts Back": false,
      "Contracts Back (Digital)" : false
    },
    "Closing" : {
      "Escrow / At Carrier": false,
      "Rescission": false
    },
    "Completed" : {
      "Closed Won" : false
    },
    "Closed" : {
      "Sent to 3rd Party" : false,
      "Client Not Interested" : false,
      "Closed Lost" : false,
      "Closed Lost no bid" : false,
      "Closed Lost with Bid" : false,
      "Unqualified Closed" : false,
      "Closed Lost to Competitor" : false
    }
  });

  const closedStatuses = [
    "Sent to 3rd Party",
    "Client Not Interested",
    "Closed Lost",
    "Closed Lost no bid",
    "Closed Lost with Bid",
    "Unqualified Closed",
    "Closed Lost to Competitor"
  ];

  const insuredFriendlyStatus = {
    "Lead" : "Sent to Marketplace",
    "Application Sent" : "Application Sent",
    "Medical Records on Order" : "Medical Records Ordered",
    "Illustration on order" : "Policy Documents Ordered",
    "Meds/Illustration Ordered" : "Med Records and Policy Docs Ordered",
    "Underwriting (LE's)" : "Medical Underwriting",
    "Pre-Priced" : "Pricing Preparation",
    "Pricing / Valuation" : "Pricing / Valuation",
    "Shipped" : "Presented to Funds",
    "Bids" : "Bidding",
    "Offer Letter Out / CRF" : "Offer Letter Sent",
    "Offer Letter In" : "Offer Letter Received",
    "Contracts Out" : "Contracts Sent",
    "Contracts Back (Digital)" : "Contracts Received",
    "Contracts Back" : "Contracts Received",
    "In Escrow" : "In Escrow",
    "At Carrier" : "At Carrier",
    "Escrow / At Carrier" : "Escrow / at Carrier",
    "Rescission" : "Rescission",
    "Closed Lost" : "Closed",
    "Closed Lost to Competitor" : "Closed",
    "Closed Lost with Bid" : "Closed",
    "Closed Lost no bid" : "Closed",
    "Sent to 3rd Party" : "Closed",
    "Client Deceased" : "Closed",
    "Closed Lost to Competitor" : "Closed",
    "Closed Won" : "Completed"
  }

  const [data, setData] = useState({
    currentPolicyKey : null,
    currentPolicyKeyPosition : null
  });

  function loadCase(data, attr){
    if(data === "reset"){
      setData({
        currentPolicyKey : null,
        currentPolicyKeyPosition : null
      });

      setPolicyPositioning({
        "Preparation" : {
          "Lead": false,
          "Application Sent": false,
          "Medical Records on Order" : false,
          "Illustration on order" : false,
          "Meds/Illustration Ordered": false,
          "Underwriting (LE's)": false,
      },
        "Valuation" : {
          "Pre-Priced": false,
          "Pricing / Valuation": false,
          "Shipped": false,
          "Bids": false,
        },
        "Contracts" : {
          "Offer Letter Out / CRF": false,
          "Offer Letter In": false,
          "Contracts Out": false,
          "Contracts Back": false,
        },
        "Closing" : {
          "Escrow / At Carrier": false,
          "Rescission": false
        },
        "Completed" : {
          "Closed Won" : false
        },
        "Closed" : {
          "Sent to 3rd Party" : false,
          "Client Not Interested" : false,
          "Closed Lost" : false,
          "Closed Lost no bid" : false,
          "Closed Lost with Bid" : false,
          "Unqualified Closed" : false,
          "Closed Lost to Competitor" : false
        }
      });

      return;
    }

    if(attr){
      setData((prev) => ({
        ...prev,
        [attr]: data,
      }));
    }else{
      setData(data);
    }
  }

  const getStatusValue = (status) => {
    for (const group in policyPositioning) {
      if (policyPositioning[group].hasOwnProperty(status)) {
        return policyPositioning[group][status];
      }
    }
    return undefined;
  };


  const getStatusIndex = (status, policyPositioning) => {
    let index = -1;
    Object.keys(policyPositioning).forEach((group, groupIndex) => {
      Object.keys(policyPositioning[group]).forEach((step, stepIndex) => {
        if (step === status) {
          index = groupIndex * 1000 + stepIndex;
        }
      });
    });

    return index;
  };

  const updateStepStatus = (statuses) => {
    if(statuses === undefined){
      return false;
    }

    setPolicyPositioning((prevPolicyPositioning) => {
      const resetPolicyPositioning = JSON.parse(JSON.stringify(prevPolicyPositioning));
  
      Object.keys(resetPolicyPositioning).forEach(group => {
        Object.keys(resetPolicyPositioning[group]).forEach(step => {
          resetPolicyPositioning[group][step] = false;
        });
      });

      for (const status of statuses) {
        for (const key in resetPolicyPositioning) {
          if (resetPolicyPositioning.hasOwnProperty(key) && resetPolicyPositioning[key].hasOwnProperty(status)) {
            resetPolicyPositioning[key][status] = true;
          }
        }
      }

      if (closedStatuses.some((closedStatus) => resetPolicyPositioning.Closed[closedStatus])) {
        let lastStatus = null;
        let lastIndex = -1;
  
        for (let i = statuses.length - 1; i >= 0; i--) {
          const status = statuses[i];
          if (!closedStatuses.includes(status)) {
            const index = getStatusIndex(status, policyPositioning);
            if (index > lastIndex) {
              lastIndex = index;
              lastStatus = status;
            }
          }
        }

        if (lastStatus) {
          for (const group in resetPolicyPositioning) {
            if (resetPolicyPositioning.hasOwnProperty(group) && resetPolicyPositioning[group].hasOwnProperty(lastStatus)) {
              resetPolicyPositioning[group][lastStatus] = "failed";
              break;
            }
          }
        }
      }

      return resetPolicyPositioning;
    });
  };

  function nextStatus() {
    let previousStatus = null;
    for (const category in policyPositioning) {

      for (const status in policyPositioning[category]) {
        if (policyPositioning[category][status] === true) {
          previousStatus = status;
        } else if (previousStatus !== null) {
          return status;
        }
      }
    }
    return null;
  }

  const checkClosedStatus = (statuses) => {
    if(statuses === undefined){
      return false;
    }

    return closedStatuses.some(status => statuses.includes(status));
  };

  const getFailedStatus = () => {
    for (const group in policyPositioning) {
      for (const status in policyPositioning[group]) {
        if (policyPositioning[group][status] === "failed") {
          return status;
        }
      }
    }

    return null;
  };

  const getFailedGroup = () => {
    for (const group in policyPositioning) {
      for (const status in policyPositioning[group]) {
        if (policyPositioning[group][status] === "failed") {
          return group;
        }
      }
    }

    return null;
  };

  function progressToBids(currentStatus) {
    const statusOrder = [
      "Lead",
      "Application Sent",
      "Medical Records on Order",
      "Illustration on order",
      "Meds/Illustration Ordered",
      "Underwriting (LE's)",
      "Pre-Priced",
      "Pricing / Valuation",
      "Shipped",
      "Bids",
      "Offer Letter Out / CRF",
      "Offer Letter In",
      "Contracts Out",
      "Contracts Back",
      "Escrow / At Carrier",
      "Rescission",
      // "Invoicing",
      "Closed Won",
      "Client Not Interested",
      "Closed Lost",
      "Closed Lost no bid",
      "Closed Lost with Bid",
      "Unqualified Closed",
      "Sent to 3rd Party"
    ];

    const bidsIndex = statusOrder.indexOf("Bids");
    const currentStatusIndex = statusOrder.indexOf(currentStatus);
  
    if (currentStatusIndex === -1) {
      return 0;
    } else if (currentStatusIndex >= bidsIndex) {
      return 100;
    } else {
      const totalSteps = bidsIndex + 1; // Adjusted to include "Bids" step
      const completedSteps = currentStatusIndex + 1;
      const percentage = (completedSteps / totalSteps) * 100;
      return percentage;
    }
  }

  function groupStatus(group){

    if(policyPositioning && group){
      const steps = Object?.keys(policyPositioning?.[group]);
      const completedSteps = steps?.filter((step) => policyPositioning?.[group]?.[step]);

      if (completedSteps.length === 0) {
        return "inactive";
      } else if (completedSteps.length === steps.length) {
        return "completed";
      } else {
        return "active";
      }
    }

    return "inactive";
  }

  function getColor(currentStep) {
    const colorGroups = [
      { group: "Preparation", color: "#fdd835", altColor: "#fffde7" },
      { group: "Valuation", color: "#33691e", altColor: "#f1f8e9" },
      { group: "Contracts", color: "#1b5e20", altColor: "#e8f5e9" },
      { group: "Closing", color: "#1565c0", altColor: "#e3f2fd" },
      { group: "Completed", color: "#ef6c00", altColor: "#fff3e0" },
      { group: "Closed", color: "#c62828", altColor: "#ffebee" },
      { group: "On Hold", color: "#ff7043", altColor: "#fbe9e7" }
    ];

    let currentGroup = null;

    for (const groupObj of colorGroups) {
      const group = groupObj.group;
      if (policyPositioning[group] && currentStep in policyPositioning[group]) {
        currentGroup = group;
        break;
      } else if (currentStep === "On Hold"){
        currentGroup = "On Hold";
        break;
      }
    }

    if (currentGroup === null) {
      return { mainColor: "Invalid step", altColor: "Invalid step" };
    }

    const colorObj = colorGroups.find(groupObj => groupObj.group === currentGroup);
    return {
      main: colorObj.color,
      altColor: colorObj.altColor
    };
  }

  function currentGroup(status) {
    for (const group in policyPositioning) {
      if (policyPositioning.hasOwnProperty(group) && policyPositioning[group].hasOwnProperty(status)) {
        return group;
      }
    }
    
    return false;
  }

  function currentProgress(currentStep) {
    if(currentStep === "On Hold"){
      return {
        progress : "100%",
        color : getColor("On Hold")
        }
    }

    let completedSteps = 0;
    let totalSteps = 0;

    let foundCurrentStep = false;
    for (const group in policyPositioning) {
      if (policyPositioning.hasOwnProperty(group) && group !== "Closed") {
        const groupSteps = Object.keys(policyPositioning[group]).length;
        totalSteps += groupSteps;

        if (!foundCurrentStep) {
          if (currentStep in policyPositioning[group]) {
            const currentStepIndex = Object.keys(policyPositioning[group]).indexOf(currentStep);
            completedSteps += currentStepIndex + 1;
            foundCurrentStep = true;
          } else {
            completedSteps += groupSteps;
          }
        }
      }
    }

    const progress = (completedSteps / totalSteps) * 100 + "%";
    const color = getColor(currentStep);

    return {
      progress,
      color
    };
  }

  function findMatchingPolicyKey(policies, currPolicy) {
    return Object.keys(policies).findIndex((key) => {
      const policy = policies[key];
      return (
        policy.recordID === currPolicy?.recordID
      );
    });
  }

  const updateCurrentPolicyKey = (newKey) => {
    const policyKeys = Object.keys(data?.policy);
    const policyKeyPosition = policyKeys.indexOf(newKey) + 1;

    setData((prev) => ({
      ...prev,
      currentPolicyKey: newKey,
      currentPolicyKeyPosition: policyKeyPosition,
    }));
  };

  const policyHandler = {
    policyPositioning : policyPositioning,
    updateStepStatus,
    groupStatus,
    currentGroup,
    currentProgress,
    checkClosedStatus,
    insuredFriendlyStatus
  }

  const functions = {
    loadCase,
    updateCurrentPolicyKey,
    currentProgress,
    progressToBids,
    getStatusValue,
    getFailedStatus,
    getFailedGroup,
    findMatchingPolicyKey,
    nextStatus
  }

  const sessionCase = {
    data,
    functions,
    trackline : {
        policyPositioning,
        setPolicyPositioning,
    },
    policyHandler
  }

  return sessionCase;
};

export default SessionCase;