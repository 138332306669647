import React, { useEffect, useState, useRef} from 'react';
import { Routes, Route } from 'react-router-dom';

import TimelineIcon from '@mui/icons-material/Timeline';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';
import AssuredWorkloadTwoToneIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

import PolicyTrackline from "./PolicyTrackline.js";
import PolicyForm from "./PolicyForm.js";
import MessageBoard from '../Components/MessageBoard/MessageBoard';
import ProgressionBar from "./ProgressionBar.js";
import Tab from '../Components/Tab.js';
import { CircularProgress } from '@mui/material';

function InsuredPortal(props){
    const session = props?.session;
    const policyHandler = session?.case?.policyHandler;
    const envFunctions = session?.env?.functions;
    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;
    const viewWidth = viewport?.width;
    const currentPage = session?.user?.data?.currentActivity;
    const userFunctions = session?.user?.functions;
    const [transcript, setTranscript] = useState();
    const iframeRef = useRef(null);
    const documentsAlt = viewType === "mobile" && currentPage === "documents";

    useEffect(() => {
      window.addEventListener('message', receiveMessage, false);
      return () => {
        window.removeEventListener('message', receiveMessage, false);
      };
    }, []);
  
    const receiveMessage = (event) => {
      if (event.data && event.data.type === 'iframeHeight') {
        const height = event.data.height;
        if (iframeRef.current) {
          iframeRef.current.style.height = height + 'px';
        }
      }
    };

    function contentClass() {
        if (currentPage === "timeline") {
          return viewType !== "mobile" ? "f gR2 mA" : "f gR3";
        } else {
          return viewType !== "mobile" ? "f" : currentPage === "details" || currentPage === "documents" ? "f" : "f gR2";
        }
    }

    function pageFormat(){
        if(viewType === "mobile"){
            if(currentPage === "details" || currentPage === "documents"){
                return "gR2";
            }

            return "gR3"
        }else{
            if(currentPage === "timeline"){
                return "gR2";
            }else{
                return '';
            }
        }
    }

    useEffect(() => {
        if(currentPage !== "messages" && transcript){
            setTranscript();
        }
    }, [currentPage]);

    return (
        <div className="insuredPortal moduleBlock fMP g oH bR gR2">
            {viewType === "mobile" &&
                <div className="tabs header f g cC fR fC">
                    <div className="f cC g">
                        <Tab
                            isActive={currentPage === "details"}
                            onClick={() => userFunctions?.updateUserData(["currentActivity"], ["details"])}
                            label="Details"
                            icon={<FolderSharedTwoToneIcon />}
                            viewType={viewType}
                            session={session}
                        />
                        <Tab
                            isActive={currentPage === "timeline"}
                            onClick={() => userFunctions?.updateUserData(["currentActivity"], ["timeline"])}
                            label="Timeline"
                            icon={<TimelineIcon />}
                            alertType={"Status"}
                            viewType={viewType}
                            session={session}
                            currentPolicy={props?.currentPolicy}
                        />
                        <Tab
                            isActive={currentPage === "documents"}
                            onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["documents"])}
                            label="Documents"
                            icon={<HistoryEduTwoToneIcon />}
                            viewType={viewType}
                            session={session}
                        />
                        <Tab
                            isActive={currentPage === "messages"}
                            onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["messages"])}
                            label="Messages"
                            icon={<ForumTwoToneIcon />}
                            viewType={viewType}
                            alertType={"Message"}
                            session={session}
                        />
                    </div>
                </div>
            }
            {!session || !props?.currentPolicy || !props?.policyTrackline ?
                <div className="loadingScreen f cC">
                    <div className="loader cC g">
                        <div className="cC g fR">
                            <div className="icon cC">
                                <CalculateTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <HandshakeTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <AssuredWorkloadTwoToneIcon/>
                            </div>
                        </div>
                        <div className="cC">
                            We're gathering your case information.
                        </div>
                    </div>
                </div>
            :
                <>
                    {viewType === "full" &&
                        <div className="f dP oA">
                            <PolicyForm
                                session={props?.session}
                                currentPolicy={props?.currentPolicy}
                                envFunctions={envFunctions}
                                policyHandler={policyHandler}
                            />
                        </div>
                    }
                    <div className={`activity f g fC oH bR ${session?.user?.data?.currentActivity}`}>
                        <Routes>
                            <Route
                                path="/details"
                                element={null}
                            />
                            <Route
                                path="/timeline"
                                element={
                                    <ProgressionBar
                                        session={props?.session}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                        viewWidth={viewWidth}
                                        currentPolicy={props?.currentPolicy}
                                    />
                                }
                            />
                            <Route
                                path="/documents"
                                element={
                                    viewType !== "mobile" ?
                                        <div className={`header bold f cC${viewType === "mobile" ? " gR2" : ''}`}>
                                            All related documents are accesible within this portal
                                        </div>
                                        :
                                        null
                                }
                            />
                            <Route
                                path="/messages"
                                element={
                                    <div className={`header bold f cC${viewType === "mobile" ? " gR2" : ''}`}>
                                        {"Messages with our team"}
                                    </div>
                                }
                            />
                        </Routes>
                        <div className={`f oA${!documentsAlt ? " dP" : ''} ` + pageFormat()}>
                            <div className={`content oH${!documentsAlt ? " bR" : ''} g ${contentClass()}`}>
                                <div className={`f tO pR${documentsAlt ? " oH" : ''}`}>
                                    <Routes>
                                        <Route
                                            path="/details"
                                            element={
                                                <PolicyForm
                                                    session={props?.session}
                                                    currentPolicy={props?.currentPolicy}
                                                    envFunctions={envFunctions}
                                                    policyHandler={policyHandler}
                                                    viewType={viewType}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/timeline"
                                            element={
                                                <PolicyTrackline
                                                    session={session}
                                                    currentPolicy={props?.currentPolicy}
                                                    envFunctions={envFunctions}
                                                    policyHandler={policyHandler}
                                                    viewType={viewType}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/documents"
                                            element={
                                                session?.shareLink?.data?.[props?.currentPolicy?.recordID]?.shareLink ?
                                                    <iframe
                                                        ref={iframeRef}
                                                        className="f"
                                                        src={session?.shareLink?.data?.[props?.currentPolicy?.recordID]?.shareLink}
                                                        allowFullScreen
                                                    />
                                                :
                                                    <div className="empty f cC dP">
                                                        <div>
                                                            {props?.initialize?.loadHandler?.shareLinkData === "pending" ?
                                                                <>
                                                                    <CircularProgress/>
                                                                    <div>
                                                                        Loading your Documents Portal.
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    <ErrorTwoToneIcon/>
                                                                    <div>
                                                                        If you're seeing this, try refereshing or reaching out to us directly so that we can get you connected to your Documents Portal.
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        />
                                        <Route
                                            path="/messages"
                                            element={
                                                <MessageBoard
                                                    session={session}
                                                    currentPolicy={props?.currentPolicy}
                                                    viewType={viewType}
                                                    transcript={transcript}
                                                    setTranscript={setTranscript}
                                                />
                                            }
                                        />
                                    </Routes>
                                </div>
                            </div>
                            {viewType !== "full" &&
                                <Routes>
                                    <Route
                                        path="/messages"
                                        element={
                                            <MessageBoard
                                                session={session}
                                                currentPolicy={props?.currentPolicy}
                                                viewType={viewType}
                                                inputOnly={true}
                                                transcript={transcript}
                                                setTranscript={setTranscript}
                                            />
                                        }
                                    />
                                    <Route path="/details" element={null}/>
                                    <Route path="/timeline" element={null}/>
                                    <Route path="/documents" element={null}/>
                                </Routes>

                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
};

export default InsuredPortal;