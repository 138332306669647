import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import MailLockRoundedIcon from '@mui/icons-material/MailLockRounded';
import CircularProgress from '@mui/material/CircularProgress';

import InputField from "../Components/InputField.js";

import Logo from '../Images/Logo.png';
import Video from '../Videos/IntroVideo.mp4';

function Login(props){
    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const initialize = props?.initialize;
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = window.location.pathname.replace(/\//g, '');
    const params = new URLSearchParams(location.search);
    const ac = params.get("ac");
    const e = params.get("e");

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [errorStatus, setErrorStatus] = useState();
    const [userData, setUserData] = useState();
    const requirements = [
        { key: "capitalLetter", label: "1 Capital Letter" },
        { key: "lowerCase", label: "1 Lowercase" },
        { key: "symbol", label: "1 Symbol" },
        { key: "number", label: "1 Number" },
        { key: "length", label: "At least 8 Characters" },
        { key: "matchingPasswords", label: "Matching Passwords" },
    ];

    const [activity, setActivity] = useState({
        currentPage : "login",
    });

    const [data, setData] = useState({
        authorization : '',
        email : '',
        password : '',
        passwordConfirmation : ''
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmission();
        }
    };

    function handleSubmission(){

        if(activity.currentPage === "login"){
            if(eligibility(["password", "email"])){
                validateUser();
            }
        }

        if(activity.currentPage === "reset"){
            if(eligibility(["email"])){
                requestTwoFactor(true);
            }
        }

        if(activity.currentPage === "create"){
            if (eligibility(["password", "passwordConfirmation"]) && requirementStatus("all")) {
                attemptTwoFactor(ac);
            }
        }

        if(activity.currentPage === "existingReset"){
            if (eligibility(["password", "passwordConfirmation", "authorization"]) && requirementStatus("all", true)) {
                attemptTwoFactor(data.authorization, data.email, true);
            }
        }

        if(activity.currentPage === "twoFactor"){
            if(eligibility(["authorization"])){
                attemptTwoFactor(data.authorization, data.email);
            }
        }
    }

    const updateUseState = (setter, attr, value) => {
        setter((prev) => ({
          ...prev,
          [attr]: value,
        }));
    }

    function eligibility(attrs) {
        for (const attr of attrs) {
            if (data[attr] === '') {
                return false;
            }
        }

        return true;
    }

    function requirementStatus(req, includeAuth) {
        const password = data?.password || '';
        const passwordConfirmation = data?.passwordConfirmation || '';
      
        const checks = {
            length: password.length >= 8,
            capitalLetter: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            symbol: /[\W_]/.test(password),
            number: /\d/.test(password),
            matchingPasswords: password !== '' && password === passwordConfirmation,
        };

        if (req === "all") {
            return (
                checks.length &&
                checks.capitalLetter &&
                checks.lowerCase &&
                checks.symbol &&
                checks.number &&
                checks.matchingPasswords &&
                (includeAuth ? data.authorization !== '' : true)
            );
        }

        if (req.includes("length") && !checks.length) {
            return false;
        }

        if (req.includes("capitalLetter") && !checks.capitalLetter) {
            return false;
        }

        if (req.includes("lowerCase") && !checks.lowerCase) {
            return false;
        }

        if (req.includes("symbol") && !checks.symbol) {
            return false;
        }

        if (req.includes("number") && !checks.number) {
            return false;
        }

        if (req.includes("matchingPasswords") && !checks.matchingPasswords) {
            return false;
        }

        return true;
    }

    function passwordRequirements() {
        return (
            <div className="prompt alt f cC g">
                <div className="f cC bold">
                    Password must include:
                </div>
                {requirements.map((requirement) => (
                    <div className="g f cL fR" key={requirement.key}>
                        <div className={`f cC${requirementStatus(requirement.key) ? " complete" : ''}`}>
                            {requirementStatus(requirement.key) ?
                                <CheckRoundedIcon />
                                :
                                <HighlightOffRoundedIcon />
                            }
                        </div>
                        <div className="f s cL">
                            {requirement.label}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function requestTwoFactor(forgotPassword){
        setLoadingStatus(true);

        const paramVals = {
            email : data?.email,
            type : forgotPassword ? "passwordReset" : "signIn",
            ...(forgotPassword ? {} : { password : data?.password })
        }

        session?.env?.functions?.buildFetchRequest("twoFactor", paramVals)
            .then(response => response.json())
            .then(resData => {    
                setLoadingStatus(false);
                if(resData.status === 200){
                    updateUseState(setActivity, "currentPage", forgotPassword ? "existingReset" : "twoFactor");
                }else{
                    setErrorStatus(resData.message);
                }
        });
    }

    if (ac && e && activity.currentPage !== "create") {
        updateUseState(setActivity, "currentPage", "create");
    }

    function attemptTwoFactor(authorization, email, reset){
        setLoadingStatus(true);
        let paramVals = {
            "authType" : e || reset ? "passwordReset" : "default",
            // "validationCode": authorization?.trim(),
            // Remove authCode when updating login system
            "authCode": authorization?.trim(),
            "emailCode": authorization?.trim(),
            ...userData,
            ...((e || reset) && {
                "email": e, 
                "password":  data.password,
                "retypePassword": data.passwordConfirmation,
            }),
            ...(email && {
                "email": email,
            })
        };

        session?.env?.functions?.buildFetchRequest("attemptAuthorization", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                const sessionUser = {
                    // ...((userData && Object.keys(userData).length > 0) ? userData : resData.authReply),
                    ...resData.authReply,
                    sessionToken : resData.sessionToken,
                    application : "iP",
                    validationCode : resData?.validationCode
                }

                setUserData(sessionUser);
                session?.user?.functions?.loadUser(sessionUser);
                session?.user?.functions?.updateUserData(["currentActivity"], ["timeline"]);

                navigate(sessionStorage.getItem('redirectLink') ?? "/timeline");
                sessionStorage.removeItem('redirectLink');
            }else{
                setErrorStatus(resData.message);
            }
        });
    }

    function validateUser(){
        setLoadingStatus(true);
        let paramVals = {
            "email" : data?.email,
            "password" : data?.password,
        };

        session?.env?.functions?.buildFetchRequest("validateUser", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                setUserData({
                    ...resData.profileData,
                    validationCode : resData?.validationCode
                });
                requestTwoFactor();
            }else{
                setErrorStatus(resData.message);
            }
        });
    }

    useEffect(() => {
        if (!ac && !e) {
            if (!["/", ""].includes(currentPath) || params.size > 0) {
                navigate("/");
            }
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ac, e]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [data, activity]);

    useEffect(() => {
        setErrorStatus();
    }, [activity.currentPage]);

    useEffect(() => {
        const loadHandler = initialize?.loadHandler;
      
        if (loadHandler && Object.values(loadHandler).some((value) => value === true)) {
          initialize.resetLoaders(session);
        }

    }, [initialize?.loadHandler, session]);

    return (
        <div className={`loginBoard g ${viewType === "mobile" ? "tC" : "cC"}`}>
            <div className="videoContainer f cC g fC">
                <div className="introVideo">
                    <video controls autoPlay muted onError={(e) => console.error("Video error:", e)}>
                        <source src={Video} type="video/mp4"/>
                    </video>
                </div>
            </div>
            <div className={"loginBlock moduleBlock bR fC g cC oH " + activity.currentPage}>
                <div className="header g cC">
                    <img className="f cC" src={Logo}/>
                    <div className="f cL s bold">
                        Abacus Life
                    </div>
                </div>
                <div className="message f cC">
                    {activity.currentPage === "login" && "Welcome"}
                    {activity.currentPage === "reset" && "Password Reset"}
                    {activity.currentPage === "create" && "Password Set"}
                    {activity.currentPage === "twoFactor" && "Authorize"}
                    {activity.currentPage === "existingReset" && "Password Reset"}
                </div>
                <div className="divider">
                </div>
                <div className="wrap f">
                    <div className={"form g f cC fC dP " + activity.currentPage}>
                        {activity.currentPage === "login" &&
                            <>
                                <div className="fields g">
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["email"]) !== '' ? " active" : ''}`}>
                                            <EmailRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.email}
                                            placeholder={"Email"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="email"
                                            setter={setData}
                                        />
                                    </div>
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.password}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Password"
                                            name="password"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP gR2">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g gR3 fC">
                                    <div className="passwordReset cC dP p bR" onClick={()=>{updateUseState(setActivity, "currentPage", "reset")}}>
                                        Forgot password?
                                    </div>
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon g bR e f p${eligibility(["password", "email"]) ? " active" : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Login
                                            </div>
                                            <div className="f cC">
                                                <LoginRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {activity.currentPage === "reset" &&
                            <>
                                <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>
                                <div className="fields g">
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["email"]) ? " active" : ''}`}>
                                            <EmailRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.email}
                                            placeholder={"Email"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="email"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                <div className="f cC prompt">
                                    Provide the email that's linked to your account.
                                    We'll send a confirmation email with a reset password link.
                                </div>
                                <div className="f cC fR">
                                    <div className="lockIcon cC">{eligibility(["email"])}
                                        {eligibility(["email"]) ? 
                                            <LockTwoToneIcon/>
                                        :
                                            <LockOpenRoundedIcon/>
                                        }
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e p${eligibility(["email"]) ? " active" : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Send Email
                                            </div>
                                            <div className="f cC">
                                                <ForwardToInboxRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {(activity.currentPage === "create" || activity.currentPage === "existingReset") &&
                            <>
                                <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>
                                <div className="fields g">
                                    {activity.currentPage === "existingReset" &&
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["authorization"]) ? " active" : ''}`}>
                                                <MailLockRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.authorization}
                                                placeholder={"Authorization Code"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="authorization"
                                                setter={setData}
                                            />                                    
                                        </div>
                                    }
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.password}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Password"
                                            name="password"
                                            setter={setData}
                                        />
                                    </div>
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["passwordConfirmation"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.passwordConfirmation}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Confirm Password"
                                            name="passwordConfirmation"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                {passwordRequirements()}
                                <div className="f cC fR">
                                    <div className="lockIcon cC">
                                        {
                                            requirementStatus("all", activity.currentPage === "existingReset") ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>
                                        }
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e${
                                                eligibility(["password", "passwordConfirmation", activity.currentPage === "existingReset" ?
                                                "authorization"
                                                : null])
                                                && requirementStatus("all", activity.currentPage === "existingReset") ?
                                                " active p"
                                                : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Login
                                            </div>
                                            <div className="f cC">
                                                <LoginRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {activity.currentPage === "twoFactor" &&
                            <>
                                <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                    <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>
                                <div className="fields g">
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["authorization"]) ? " active" : ''}`}>
                                            <MailLockRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.authorization}
                                            placeholder={"Authorization Code"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="authorization"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                <div className="f cC prompt">
                                    We've sent an authorization code to your email. Please provide the code to proceed with logging in.
                                </div>
                                <div className="f cC fR">
                                    <div className="lockIcon cC">{eligibility(["authorization"])}
                                        {eligibility(["authorization"]) ? 
                                            <LockTwoToneIcon/>
                                        :
                                            <LockOpenRoundedIcon/>
                                        }
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        Oops! The code you entered doesn't match the one we sent to your email.
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e p${eligibility(["authorization"]) ? " active" : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Authorize
                                            </div>
                                            <div className="f cC">
                                                <KeyRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;